.tabs[data-v-9513e35c] {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(229, 229, 229);
  margin-bottom: 10px;
}
.tabs img[data-v-9513e35c] {
  width: 147px;
  height: 80px;
}
.tab_content[data-v-9513e35c] {
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 8px;
}
.tab_content.active[data-v-9513e35c] {
  color: #17a2b8;
  border-bottom: 1px solid #17a2b8;
}
.tab_title[data-v-9513e35c] {
  font-size: 13px;
}
.triangle-container[data-v-9513e35c] {
  margin: 0 65px;
}
.triangle-container img[data-v-9513e35c] {
  width: 26px;
  height: auto;
}
.top[data-v-9513e35c] {
  margin-bottom: 10px;
}
.bg-purple-light[data-v-9513e35c] {
  float: right;
}