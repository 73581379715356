.opera_icon[data-v-b37c5a6c] {
  cursor: pointer;
  font-size: 14px;
  top: 2px;
}
.styleForm .el-form-item[data-v-b37c5a6c] {
  margin-bottom: 5px;
}
.left_box[data-v-b37c5a6c] {
  float: left;
  margin-top: 11px;
  width: 2%;
}
.left_box input[data-v-b37c5a6c] {
  width: 80%;
  height: 16px;
}
.top-nav[data-v-b37c5a6c] {
  height: 36px;
  border: 1px solid #b9b9b9;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
}
.top-nav .box-content[data-v-b37c5a6c] {
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #b9b9b9;
  line-height: 34px;
  font-size: 13px;
  height: 34px;
}
.right_box[data-v-b37c5a6c] {
  float: left;
  width: 100%;
}
.for-content .box-content[data-v-b37c5a6c] {
  box-sizing: border-box;
  border-left: 1px solid #b9b9b9;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #b9b9b9;
  padding: 0 10px;
}
.for-content .el-col:nth-child(10) .box-content[data-v-b37c5a6c] {
  border-right: 1px solid #b9b9b9;
}
.for-content .el-col:nth-child(1) .box-content[data-v-b37c5a6c] {
  border-left: none;
}
.table_height[data-v-b37c5a6c] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.content-nav[data-v-b37c5a6c] {
  line-height: 36px;
  border: 1px solid #b9b9b9;
}
.content-nav .content-top[data-v-b37c5a6c] {
  background: var(--themeColorLeftMenu, #ecf7f9);
}
.content-nav .content-top .box-content[data-v-b37c5a6c] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #b9b9b9;
}
.content-nav .content-top .box-content[data-v-b37c5a6c]:first-child(1) {
  border-left: none;
}
.content-nav .for-content .box-content[data-v-b37c5a6c] {
  box-sizing: border-box;
  border-left: 1px solid #b9b9b9;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #b9b9b9;
  padding: 0 10px;
  color: #212529;
}
.content-nav .for-content .el-col:nth-child(9) .box-content[data-v-b37c5a6c] {
  border-right: 1px solid #b9b9b9;
}
.content-nav .for-content .el-col:nth-child(1) .box-content[data-v-b37c5a6c] {
  border-left: none;
}
.content-nav .bottom-content .box-content[data-v-b37c5a6c] {
  box-sizing: border-box;
  border-left: 1px solid #b9b9b9;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  color: #212529;
}
.content-nav .bottom-content .el-col:nth-child(1) .box-content[data-v-b37c5a6c] {
  border-left: none;
}
.content-nav .bottom-content .el-col:nth-child(7) .box-content[data-v-b37c5a6c] {
  border-right: 1px solid #b9b9b9;
}
.top_font[data-v-b37c5a6c] {
  float: left;
  width: 87%;
  margin: 0 auto;
}
.top_font li[data-v-b37c5a6c] {
  font-size: 13px;
  color: #212529;
  float: left;
  text-align: left;
  padding-left: 5px;
}
.top_right_btns[data-v-b37c5a6c] {
  float: right;
  margin-right: 5px;
  position: relative;
}
.top_right_btns i[data-v-b37c5a6c] {
  margin: 0 3px;
  cursor: pointer;
  font-size: 17px;
  line-height: 24px;
}
.ccyc[data-v-b37c5a6c] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selects[data-v-b37c5a6c] .tags-select-input {
  height: 24px;
}
.selects[data-v-b37c5a6c] .el-select__tags {
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: nowrap;
}
.selects[data-v-b37c5a6c] .el-select__tags-text {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.operation_btn[data-v-b37c5a6c] {
  margin-left: 5px;
}
.center_big[data-v-b37c5a6c] {
  position: relative;
}
.fold_icon[data-v-b37c5a6c] {
  position: absolute;
  left: 50%;
  bottom: -10px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}