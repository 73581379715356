.top_select[data-v-2afe4126] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hint[data-v-2afe4126] {
  font-size: 14px;
  color: red;
  margin-left: 5px;
}