.content_table[data-v-fcd5f25a] {
  border: 1px solid #c2c2c2;
  width: 100%;
  border-right: none;
  border-bottom: none;
}
.content_table td[data-v-fcd5f25a] {
  border-bottom: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
  text-align: center;
  line-height: 32px;
}