.select-inventory-list-container[data-v-b603f486] {
  position: relative;
  width: 200px;
  height: 24px;
}
.select-inventory-list-container .text-box[data-v-b603f486] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border: 1px solid #b9b9b9;
}
.select-inventory-list-container .text-box > span[data-v-b603f486] {
  flex: 1;
  width: 0;
}
.select-inventory-list-container .render-box[data-v-b603f486] {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}