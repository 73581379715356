
.ant-select-dropdown {
  z-index: 5000;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--themeColor, #17a2b8);
  font-weight: 700;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 12px;
}
