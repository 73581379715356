
.el-message-box{
  width: 800px;
}
.sel_class .el-input__inner{
  border-color: transparent !important;
}
.top_price .el-input-group--append .el-input__inner,.top_price .el-input-group__prepend{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.top_price .el-select .el-input.is-focus .el-input__inner,.top_price .el-select .el-input:hover .el-input__inner{
  border-color: transparent !important;
}
.top_price .el-input-group--prepend .el-input__inner,.top_price .el-input-group__append{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

