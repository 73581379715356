.styleForm .el-form-item[data-v-d1a443bc] {
  margin-bottom: 5px;
}
.left_box[data-v-d1a443bc] {
  float: left;
  margin-top: 11px;
  width: 2%;
}
.left_box input[data-v-d1a443bc] {
  width: 80%;
  height: 16px;
}
.top-nav[data-v-d1a443bc] {
  height: 36px;
  border: 1px solid #b9b9b9;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
}
.top-nav .box-content[data-v-d1a443bc] {
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #b9b9b9;
  line-height: 34px;
  font-size: 13px;
  height: 34px;
}
.right_box[data-v-d1a443bc] {
  float: left;
  width: 100%;
}
.for-content .box-content[data-v-d1a443bc] {
  box-sizing: border-box;
  border-left: 1px solid #b9b9b9;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #b9b9b9;
  padding: 0 10px;
}
.for-content .el-col:nth-child(10) .box-content[data-v-d1a443bc] {
  border-right: 1px solid #b9b9b9;
}
.for-content .el-col:nth-child(1) .box-content[data-v-d1a443bc] {
  border-left: none;
}
.table_height[data-v-d1a443bc] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.content-nav[data-v-d1a443bc] {
  line-height: 36px;
  border: 1px solid #b9b9b9;
}
.content-nav .content-top[data-v-d1a443bc] {
  background: var(--themeColorLeftMenu, #ecf7f9);
}
.content-nav .content-top .box-content[data-v-d1a443bc] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #b9b9b9;
}
.content-nav .content-top .box-content[data-v-d1a443bc]:first-child(1) {
  border-left: none;
}
.content-nav .for-content .box-content[data-v-d1a443bc] {
  box-sizing: border-box;
  border-left: 1px solid #b9b9b9;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #b9b9b9;
  padding: 0 10px;
  color: #212529;
}
.content-nav .for-content .el-col:nth-child(9) .box-content[data-v-d1a443bc] {
  border-right: 1px solid #b9b9b9;
}
.content-nav .for-content .el-col:nth-child(1) .box-content[data-v-d1a443bc] {
  border-left: none;
}
.content-nav .bottom-content .box-content[data-v-d1a443bc] {
  box-sizing: border-box;
  border-left: 1px solid #b9b9b9;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  color: #212529;
}
.content-nav .bottom-content .el-col:nth-child(1) .box-content[data-v-d1a443bc] {
  border-left: none;
}
.content-nav .bottom-content .el-col:nth-child(7) .box-content[data-v-d1a443bc] {
  border-right: 1px solid #b9b9b9;
}
.top_font[data-v-d1a443bc] {
  float: left;
  width: 84%;
  margin: 0 auto;
}
.top_font li[data-v-d1a443bc] {
  font-size: 13px;
  color: #212529;
  float: left;
  text-align: left;
  padding-left: 15px;
}
.top_right_btns[data-v-d1a443bc] {
  float: right;
  margin-right: 16px;
}
.top_right_btns i[data-v-d1a443bc] {
  margin: 0 5px;
  cursor: pointer;
  font-size: 17px;
  line-height: 24px;
}
.ccyc[data-v-d1a443bc] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dfyhm[data-v-d1a443bc] {
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  outline: none;
  width: 250px;
  line-height: 20px;
  margin-top: 6px;
}
.table_height[data-v-d1a443bc] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.bank-table-header[data-v-d1a443bc] {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #212529;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 13px;
}
.bank-table-header > div[data-v-d1a443bc] {
  border-right: 1px solid #c2c2c2;
  text-align: center;
  flex: 1;
}
.bank-table-header > div[data-v-d1a443bc]:last-child {
  border-right: 0;
}
.bank-table-header input[data-v-d1a443bc] {
  position: absolute;
  zoom: 120%;
  left: 5px;
  top: 50%;
  transform: translateY(-50%) !important;
}
.bank-item-container[data-v-d1a443bc] {
  margin-bottom: 10px;
  border: 1px solid #c2c2c2;
  border-bottom: 0 !important;
}
.bank-item-header[data-v-d1a443bc] {
  width: 100%;
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 20px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  position: relative;
  border-bottom: 1px solid #c2c2c2;
}
.bank-item-header > div[data-v-d1a443bc] {
  margin-right: 30px;
  display: inline-block;
}
.bank-item-header .item-input[data-v-d1a443bc] {
  position: absolute;
  left: 5px;
  zoom: 110%;
  top: 50%;
  transform: translateY(-50%);
}
.top_right_btns[data-v-d1a443bc] {
  float: right;
  text-align: center;
  position: relative;
}
.top_right_btns i[data-v-d1a443bc] {
  margin: 0 5px;
  cursor: pointer;
  font-size: 17px;
  line-height: 24px;
}
.item_box[data-v-d1a443bc] {
  width: 100%;
  font-size: 10px;
}
.item_box tr[data-v-d1a443bc] {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.item_box tr td[data-v-d1a443bc] {
  flex: 1;
  line-height: 34px;
  border-color: #c2c2c2 !important;
  border-bottom: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
  padding: 0 10px;
}
.bank-item-header-caozuo[data-v-d1a443bc] {
  float: right;
  margin-right: 15px;
}
.bank-item-header-caozuo i[data-v-d1a443bc] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 17px;
  line-height: 20px;
}
.center_big[data-v-d1a443bc] {
  position: relative;
}
.fold_icon[data-v-d1a443bc] {
  position: absolute;
  left: 50%;
  bottom: -10px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}