.addLis[data-v-4cba94b5] {
  display: flex;
  justify-content: right;
  margin-right: 60px;
}
.left_box[data-v-4cba94b5] {
  float: left;
  width: 2%;
}
.left_box input[data-v-4cba94b5] {
  width: 80%;
  height: 16px;
}
.right_btns[data-v-4cba94b5] {
  width: 3%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.top_title[data-v-4cba94b5] {
  display: flex;
  align-items: center;
  width: 100%;
}
.right_box[data-v-4cba94b5] {
  width: 100%;
}
.top-nav[data-v-4cba94b5] {
  height: 36px;
  border: 1px solid #c2c2c2;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
}
.top-nav .box-content[data-v-4cba94b5] {
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #c2c2c2;
  line-height: 34px;
  font-size: 13px;
  height: 34px;
}
.bottom-nav[data-v-4cba94b5] {
  height: 36px;
  font-weight: 500;
  position: relative;
}
.bottom-nav .box-content[data-v-4cba94b5] {
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #c2c2c2;
  line-height: 34px;
  font-size: 13px;
  height: 34px;
}
.table_height[data-v-4cba94b5] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.content_center[data-v-4cba94b5] {
  display: flex;
  align-items: center;
}
.content-nav[data-v-4cba94b5] {
  line-height: 36px;
  border: 1px solid #c2c2c2;
}
.content-nav .content-top[data-v-4cba94b5] {
  background: var(--themeColorLeftMenu, #ecf7f9);
}
.content-nav .content-top .box-content[data-v-4cba94b5] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #c2c2c2;
}
.content-nav .content-top .box-content[data-v-4cba94b5]:first-child(1) {
  border-left: none;
}
.content-nav .for-content .box-content[data-v-4cba94b5] {
  box-sizing: border-box;
  border-left: 1px solid #c2c2c2;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  border-bottom: 1px solid #c2c2c2;
  padding: 0 10px;
}
.content-nav .for-content .el-col:nth-child(9) .box-content[data-v-4cba94b5] {
  border-right: 1px solid #c2c2c2;
}
.content-nav .for-content .el-col:nth-child(1) .box-content[data-v-4cba94b5] {
  border-left: none;
}
.content-nav .bottom-content .box-content[data-v-4cba94b5] {
  box-sizing: border-box;
  border-left: 1px solid #c2c2c2;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
}
.content-nav .bottom-content .el-col:nth-child(1) .box-content[data-v-4cba94b5] {
  border-left: none;
}
.content-nav .bottom-content .el-col:nth-child(7) .box-content[data-v-4cba94b5] {
  border-right: 1px solid #c2c2c2;
}
.top_font[data-v-4cba94b5] {
  float: left;
  width: 83%;
  margin: 0 auto;
}
.top_font li[data-v-4cba94b5] {
  font-size: 13px;
  color: #333;
  float: left;
  text-align: left;
  padding-left: 15px;
  margin-right: 10px;
}
.top_right_btns[data-v-4cba94b5] {
  float: right;
  margin-right: 40px;
}
.top_right_btns i[data-v-4cba94b5] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 17px;
  line-height: 24px;
}
.top_right_btn2[data-v-4cba94b5] {
  float: right;
  margin-right: 40px;
}
.top_right_btn2 i[data-v-4cba94b5] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 17px;
  line-height: 24px;
}
.el-col-3[data-v-4cba94b5] {
  border-right: 1px solid #cfc2c2;
  text-align: center;
}
.el-col-2[data-v-4cba94b5] {
  border-right: 1px solid #cfc2c2;
  text-align: center;
}
.el-col-4[data-v-4cba94b5] {
  border-right: 1px solid #cfc2c2;
  text-align: center;
}
.el-col-3 .el-input[data-v-4cba94b5] {
  width: 90%;
}
.el-col-2 .el-input[data-v-4cba94b5] {
  width: 90%;
}
.select-trigger .el-input[data-v-4cba94b5] {
  border: 1px solid #eee !important;
}