.title[data-v-0aea7252] {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #333;
}
.leftBtnNone[data-v-0aea7252] .el-carousel__arrow--left {
  display: none !important;
}
.rightBtnNone[data-v-0aea7252] .el-carousel__arrow--right {
  display: none !important;
}