.title[data-v-afeeeeba] {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #333;
}
.pic[data-v-afeeeeba] {
  position: relative;
}
.img_right[data-v-afeeeeba] {
  position: absolute;
  right: -5px;
  top: 35%;
  writing-mode: tb-rl;
  letter-spacing: 3px;
}